import React from 'react'
import { Layout, Warpper } from '@raylink/common/components'
import styled from 'styled-components'
import { Media } from '@raylink/common/theme'
import Bg404Src from '../images/404.png'
import { useHref } from '@raylink/common/hooks'

const NotFoundWrapper = styled(Warpper)`
  padding: 209px 0 204px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 12px;
    color: #212121;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #595959;
  }
  a {
    display: block;
    padding: 8px 24px;
    background: #11c871;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 32px;
  }
  a:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      rgb(17, 200, 113);
  }
  ${Media.phone`
    padding: 3.48rem 0 3.64rem;
    img {
      width: 5.56rem;
    }
    .title {
      font-size: 0.4rem;
      line-height: 0.72rem;
      margin-bottom: 0.16rem;
    }
    p {
      font-size: 0.28rem;
      line-height: 0.54rem;
    }
    a {
      padding: 0.16rem 0.48rem;
      border-radius: 0.12rem;
      font-size: 0.28rem;
      margin-top: 0.48rem;
    }
  `}
`

const NotFoundPage = () => {
  const paramsHref = useHref()
  return (
    <Layout pageType="home">
      <NotFoundWrapper>
        <img src={Bg404Src} alt="" loading="lazy" />
        <div className="title">出错啦！</div>
        <div className="pc-desc">
          <p>抱歉！您访问的地址有误或者页面不存在</p>
        </div>
        <a href={paramsHref('/')}>返回首页</a>
      </NotFoundWrapper>
    </Layout>
  )
}

export default NotFoundPage
